<template>
    <div class="icon-progress-complete">
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
            <rect x="1.5" y="1.5" width="30" height="30" rx="15" fill="#A7D0F8" />
            <rect x="1.5" y="1.5" width="30" height="30" rx="15" stroke="#A7D0F8" stroke-width="2" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.2934 10.3532L13.7467 19.5666L11.2134 16.8599C10.7467 16.4199 10.0134 16.3932 9.48005 16.7666C8.96005 17.1532 8.81338 17.8332 9.13338 18.3799L12.1334 23.2599C12.4267 23.7132 12.9334 23.9932 13.5067 23.9932C14.0534 23.9932 14.5734 23.7132 14.8667 23.2599C15.3467 22.6332 24.5067 11.7132 24.5067 11.7132C25.7067 10.4866 24.2534 9.40655 23.2934 10.3399V10.3532Z" fill="white" />
        </svg>
    </div>
</template>

<script setup lang="ts">
</script>

<style>
    .icon-progress-complete {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }
</style>